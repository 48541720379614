<template>
  <button
    :disabled="disabled"
    @click="($event) => $emit('clicked', $event)"
    type="button"
    :class="[baseClasses, classes[color], full ? 'w-full' : '', disabled ? 'opacity-70' : '']"
  >
    <slot></slot>
  </button>
</template>
<script setup lang="ts">
defineEmits(['clicked'])
withDefaults(
  defineProps<{
    color?: 'primary' | 'danger' | 'light'
    full?: boolean
    disabled?: boolean
  }>(),
  { color: 'primary', full: false, disabled: false }
)
const baseClasses =
  'cursor-pointer text-center rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm'
const classes = {
  primary: `bg-blue-600 text-white hover:bg-blue-500`,
  light: `bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50`,
  danger: `bg-red-600 text-white hover:bg-red-500`
}
</script>
