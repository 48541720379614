import * as iban from 'iban'
import type { FormKitNode } from '@formkit/core'

const ibanValidator = function (node: FormKitNode) {
  return iban.isValid(node.value as string)
}

ibanValidator.blocking = true

export default ibanValidator
