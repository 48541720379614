import { supabase } from '@/lib/supabaseClient'
import type { CreateDocumentPage, DocumentData } from '@/types/types'
import { v4 as uuidv4 } from 'uuid'
import imageCompression from 'browser-image-compression'

export default async function (
  originalFiles: { file: File; skipUpload: boolean }[],
  pages: CreateDocumentPage[]
) {
  const docId = uuidv4()

  const {
    data: { user }
  } = await supabase.auth.getUser()
  if (!user) throw new Error(`No user session`)
  const userId = user.id
  const originals = originalFiles
    .filter(({ skipUpload }) => !skipUpload)
    .map(({ file }) => ({
      file,
      path: `${userId}/${docId}/originals/${uuidv4()}`
    }))

  const imageFiles = await Promise.all(
    pages.map(async (page) => ({
      file: await imageCompression(page.edited, {
        maxSizeMB: 0.8,
        maxWidthOrHeight: 1500,
        useWebWorker: true
      }),
      path: `${userId}/${docId}/images/${uuidv4()}`
    }))
  )

  const { error: uploadingDocError } = await supabase.from('Documents').insert({
    id: docId,
    status: 'UPLOADING',
    imagePaths: imageFiles.map(({ path }) => path),
    originalFilePaths: originals.map(({ path }) => path)
  })

  if (uploadingDocError) throw uploadingDocError

  try {
    const originalUploads = Promise.all(
      originals.map(({ file, path }) => supabase.storage.from('documents').upload(path, file))
    )
    const imageUploads = Promise.all(
      imageFiles.map(({ file, path }) => supabase.storage.from('documents').upload(path, file))
    )
    await Promise.all([originalUploads, imageUploads])
  } catch (error) {
    console.log(error)
    alert(`Upload error, please try again.`)
    return
  }

  const { data: formattedDoc, error: updateError } = await supabase
    .from('Documents')
    .update({
      status: 'FORMATTED'
    })
    .eq('id', docId)
    .select()
    .single()

  if (updateError) throw updateError
  return formattedDoc as DocumentData
}
