<template>
  <TransitionRoot as="template" :show="isOpen(id)" @after-leave="removeModal(id)">
    <Dialog
      as="div"
      class="relative z-10"
      @close="backdropDismiss && requestDismiss()"
      :initial-focus="null"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div
        class="fixed inset-0 z-10 overflow-y-auto pt-[var(--ion-safe-area-top)] pb-[var(--ion-safe-area-bottom)]"
      >
        <div
          class="flex min-h-full justify-center p-4 text-center sm:p-0"
          :class="id === 'loading' ? 'items-center' : 'items-start'"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <component
              :is="component"
              v-bind="componentProps"
              @dismiss="(data: any) => requestDismiss(data)"
              @setBeforeDismiss="(callback: any) => beforeDismiss = callback"
              @setAfterDismiss="(callback: any) => afterDismiss = callback"
            />
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useModalStore } from '@/stores/modal'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { onBeforeUnmount } from 'vue'
import { onMounted } from 'vue'

let beforeDismiss: (() => Promise<boolean>) | undefined
let afterDismiss: (() => Promise<boolean>) | undefined

const props = defineProps<{ id: string }>()
const store = useModalStore()
const { dismissModal, removeModal, isOpen } = store
const { modals, topModal } = storeToRefs(store)
const { component, componentProps, backdropDismiss } = modals.value.get(props.id)!
let unsubscribe: () => void

onMounted(() => {
  unsubscribe = useRouter().beforeEach(() => {
    const isTop = props.id === 'loading' || topModal.value?.id === props.id
    if (isTop && isOpen(props.id)) {
      if (backdropDismiss) requestDismiss()
      return false
    }
  })
  onBeforeUnmount(unsubscribe)
})

const requestDismiss = async (data?: any) => {
  const isTop = topModal.value?.id === props.id
  if (isTop && (!beforeDismiss || (await beforeDismiss()))) {
    unsubscribe()
    dismissModal(props.id, data)
    !!afterDismiss && afterDismiss()
  }
}
</script>
