import { defineStore } from 'pinia'
import { shallowRef, type ComponentCustomProps } from 'vue'
import type { Component } from 'vue'
import LoadingModalVue from '@/components/LoadingModal.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import AlertModal from '@/components/AlertModal.vue'

type ConfirmModalProps = InstanceType<typeof ConfirmModal>['$props']
type AlertModalProps = InstanceType<typeof AlertModal>['$props']

interface State {
  modals: Map<
    string,
    {
      id: string
      component: Component
      componentProps?: ComponentCustomProps
      open: boolean
      backdropDismiss?: boolean
    }
  >
}

export const useModalStore = defineStore('modal', {
  state: (): State => ({
    modals: new Map()
  }),
  getters: {
    isOpen: ({ modals }) => {
      return (id: string) => !!modals.get(id)?.open
    },
    topModal: ({ modals }) => {
      const modalsCopy = new Map(modals)
      modalsCopy.delete('loading')
      return [...modalsCopy.values()].filter(({ open }) => open).pop()
    }
  },
  actions: {
    async presentModal(
      {
        component,
        componentProps,
        backdropDismiss = true
      }: {
        component: Component
        componentProps?: any
        backdropDismiss?: boolean
      },
      id?: string
    ): Promise<void | any> {
      const { $onAction } = useModalStore()
      const modalId = id ?? `modal-${this.modals.size + 1}`
      this.modals.set(modalId, {
        id: modalId,
        open: true,
        component: shallowRef(component),
        componentProps,
        backdropDismiss
      })
      return new Promise((resolve) => {
        const unsubscribe = $onAction(({ name, args }) => {
          if (name === 'dismissModal') {
            const [id, data] = args
            if (id === modalId) {
              unsubscribe()
              resolve(data)
            }
          }
        })
      })
    },
    presentLoader() {
      this.modals.set('loading', {
        id: 'loading',
        open: true,
        component: shallowRef(LoadingModalVue)
      })
    },
    clearModals() {
      ;[...this.modals.keys()].forEach((id) => this.dismissModal(id))
    },
    dismissLoader() {
      this.dismissModal('loading')
    },
    removeModal(id: string) {
      this.modals.delete(id)
    },
    dismissModal(id: string, data?: any) {
      console.log(data)
      this.modals.set(id, { ...this.modals.get(id)!, open: false })
    },
    presentConfirm(componentProps: ConfirmModalProps, dismissable: boolean = true) {
      return this.presentModal({
        component: ConfirmModal,
        componentProps,
        backdropDismiss: dismissable
      })
    },
    presentAlert(componentProps: AlertModalProps) {
      return this.presentModal({ component: AlertModal, componentProps })
    }
  }
})
