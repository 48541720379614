import { supabase } from '@/lib/supabaseClient'
import { v4 as uuidv4 } from 'uuid'
import type { CertificateFormData, CertificationFormData, DocumentData } from '@/types/types'

function getCertTypeFields(certification: CertificateFormData['certifications'][0]) {
  if (!!certification.otherCertType) return { name: certification.otherCertType }
  else
    return {
      certificateTypeId: certification.certificateTypeId || certification.groupOrCertificateTypeId
    }
}

export default async function (certificateForm: {
  certificateIssuerId: string
  documentRef: string
  public: boolean
  certificateTypeId: string
  certifications: CertificationFormData[]
  id: string
  imagePaths: string[]
  originalFilePaths: string[]
}) {
  const [firstCertification, ...otherCertifications] = certificateForm.certifications

  const commonCertificateData = {
    categoryId: 'CERTIFICATE',
    documentRef: certificateForm.documentRef,
    certificateIssuerId: certificateForm.certificateIssuerId,
    public: certificateForm.public
  }

  return Promise.all([
    supabase
      .from('Documents')
      .update({
        ...commonCertificateData,
        ...getCertTypeFields(firstCertification),
        expiryDate: firstCertification.expiryDate || null,
        issueDate: firstCertification.issueDate || null,
        status: 'COMPLETE'
      })
      .eq('id', certificateForm.id)
      .select('*, certificateIssuerId (name)')
      .single()
      .then(({ data }) => data),
    ...otherCertifications.map(async (certification) => {
      const docId = uuidv4()

      const imagePaths: string[] = JSON.parse(
        JSON.stringify(certificateForm.imagePaths).replace(
          new RegExp(certificateForm.id, 'g'),
          docId
        )
      )
      const originalFilePaths: string[] = JSON.parse(
        JSON.stringify(certificateForm.originalFilePaths).replace(
          new RegExp(certificateForm.id, 'g'),
          docId
        )
      )

      await supabase.from('Documents').insert({
        id: docId,
        ...commonCertificateData,
        ...getCertTypeFields(certification),
        status: 'UPLOADING',
        expiryDate: certification.expiryDate || null,
        issueDate: certification.issueDate || null,
        imagePaths,
        originalFilePaths
      })

      const originalCopyFilePaths = [
        ...certificateForm.imagePaths!,
        ...certificateForm.originalFilePaths!
      ]
      const newCopyFilePaths = [...imagePaths, ...originalFilePaths]

      await Promise.all(
        originalCopyFilePaths.map((originalCopyPath, index) =>
          supabase.storage.from('documents').copy(originalCopyPath, newCopyFilePaths[index])
        )
      )
      const { data: newDoc } = await supabase
        .from('Documents')
        .update({
          status: 'COMPLETE',
          public: certificateForm.public
        })
        .eq('id', docId)
        .select('*, certificateIssuerId (name)')
        .single()
      return newDoc
    })
  ])
}
