import type { CreateDocumentPage, DocumentData } from '@/types/types'
import { v4 as uuidv4 } from 'uuid'
import imageCompression from 'browser-image-compression'
import { supabase } from '@/lib/supabaseClient'

export default async function (
  doc: Partial<DocumentData> & Pick<DocumentData, 'id' | 'originalFilePaths'>,
  originalFiles: { file: File; skipUpload: boolean }[],
  pages: CreateDocumentPage[]
) {
  const {
    data: { user }
  } = await supabase.auth.getUser()

  if (!user) throw new Error(`No user session`)

  const userId = user.id
  const extraOriginalFiles = originalFiles
    .filter(({ skipUpload }) => !skipUpload)
    .map(({ file }) => ({
      file,
      path: `${userId}/${doc.id}/originals/${uuidv4()}`
    }))
  const imageFiles = await Promise.all(
    pages.map(async (page) => ({
      file: await imageCompression(page.edited, {
        maxSizeMB: 0.8,
        maxWidthOrHeight: 1500,
        useWebWorker: true
      }),
      path: `${userId}/${doc.id}/images/${uuidv4()}`
    }))
  )

  const imagePaths = imageFiles.map(({ path }) => path)

  const { error: updateDocError } = await supabase
    .from('Documents')
    .update({
      imagePaths,
      originalFilePaths: [...doc!.originalFilePaths!, ...extraOriginalFiles.map(({ path }) => path)]
    })
    .eq('id', doc.id)

  if (updateDocError) throw updateDocError

  if (!!imagePaths.length) {
    try {
      const originalUploads = Promise.all(
        extraOriginalFiles.map(({ file, path }) =>
          supabase.storage.from('documents').upload(path, file)
        )
      )
      const imageUploads = Promise.all(
        imageFiles.map(({ file, path }) => supabase.storage.from('documents').upload(path, file))
      )
      await Promise.all([originalUploads, imageUploads])
    } catch (error) {
      console.log(error)
      alert(`Upload error, please try again.`)
      return
    }

    const { data: formattedDoc, error: formattedDocError } = await supabase
      .from('Documents')
      .update({
        status: 'FORMATTED'
      })
      .eq('id', doc.id)
      .select()
      .single()
    if (formattedDocError) throw formattedDocError
    return formattedDoc as DocumentData
  }
}
