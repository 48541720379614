<template>
  <FormKit
    v-if="!certificateTypesLoading"
    type="autocomplete"
    name="groupOrCertificateTypeId"
    label="Certificate type"
    ref="certificateTypeInput"
    placeholder="Start typing..."
    :disabled="certificateTypesLoading"
    :options="searchCertificateTypes"
    :option-loader="getInitialValue"
    :open-on-click="true"
    @blur="$emit('blur')"
    @input="$emit('input')"
    validation="required"
    v-model="type"
    data-test-id="certificate-form-id"
  >
    <template #option="{ option }">
      <div class="formkit-option">
        {{ option.label }}
        <span class="italic" v-if="option.matches?.length">({{ option.matches.join(', ') }})</span>
      </div>
    </template>
  </FormKit>
  <FormKit
    v-else
    type="autocomplete"
    label="Certificate type"
    placeholder="Start typing..."
    :disabled="true"
    :options="[]"
    :ignore="true"
  />
  <FormKit
    type="text"
    name="otherCertType"
    validation="required"
    v-if="type === 'OTHER'"
    label="Other certificate type"
    help="What is this certificate?"
  ></FormKit>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useCertificateListQuery } from '@/queries/certificateList'
import FlexSearch from 'flexsearch'

let searchIndex: FlexSearch.Document<any>

const props = withDefaults(defineProps<{ allowOther?: boolean }>(), { allowOther: true })

defineEmits(['input', 'blur'])

const type = ref()

const certificateTypeInput = ref()

const { data: certificateTypes, isLoading: certificateTypesLoading } = useCertificateListQuery()

const getInitialValue = async (initialValue: string | null, cachedOption: any) => {
  if (cachedOption) return cachedOption
  const label = !!initialValue
    ? certificateTypes.value!.get(initialValue)?.name ?? 'Error, type not found'
    : undefined

  return !!label
    ? {
        value: initialValue,
        label
      }
    : false
}

const searchCertificateTypes = async ({ search }: any) => {
  let allResults: any[] = []
  if (props.allowOther) allResults.push({ label: 'Other', value: 'OTHER' })
  if (!!search) {
    if (!searchIndex) {
      searchIndex = new FlexSearch.Document({
        document: { id: 'id', index: [{ field: 'name', tokenize: 'reverse' }] }
      })
      certificateTypes.value!.forEach((certificateType) => searchIndex.add(certificateType))
    }
    const searchResults =
      searchIndex.search(search)[0]?.result.map((id) => ({
        value: id,
        label: certificateTypes.value?.get(id as string)?.name
      })) ?? []

    allResults.push(...searchResults)
  } else {
    allResults.unshift(
      ...([...certificateTypes.value!.values()] || []).map(({ name, id }: any) => ({
        label: name,
        value: id
      }))
    )
  }
  return allResults
}
</script>
