<template>
  <IonApp>
    <ion-router-outlet></ion-router-outlet>
    <ModalOutlet />
  </IonApp>
</template>

<script setup lang="ts">
import setLocale from '@/helpers/setLocale'
import ModalOutlet from '@/components/ModalOutlet.vue'
import { IonApp } from '@ionic/vue'
setLocale()
</script>
