import { supabase } from '@/lib/supabaseClient'
import { useQuery } from '@tanstack/vue-query'

export function useDocumentsQuery() {
  return useQuery({
    queryKey: ['documents'],
    queryFn: async () => {
      const {
        data: { user }
      } = await supabase.auth.getUser()
      return !!user
        ? supabase
            .from('Documents')
            .select(`*`)
            .is('archivedAt', null)
            .eq('userId', user!.id)
            .order('expiryDate', { ascending: true })
        : {}
    },
    select: ({ data }: any) => data
  })
}
