import type { DocumentData, ScanResult } from '@/types/types'
import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'

export function useDocumentScanQuery(doc: Pick<DocumentData, 'id' | 'imagePaths' | 'data'>) {
  return useQuery<ScanResult>({
    queryKey: ['documentScans', doc.id],
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (doc.data?.scanResult && import.meta.env.PROD) return doc.data.scanResult

      const { data: scanData } = await axios.post(
        `/documentScanner`,
        { documentId: doc.id },
        { timeout: 10000 }
      )

      const { certifications, ...scanResult }: any = scanData
      if (!!certifications?.length) {
        scanResult.certifications = certifications.map(({ id, ...certification }: any) => ({
          groupOrCertificateTypeId: id,
          ...certification
        }))
      }
      return scanResult
    }
  })
}
