<template>
  <DialogPanel
    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-screen-xl"
  >
    <ModalHeader :title="getTitle" @close="$emit('dismiss')" />
    <div class="p-4 sm:p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 bg-gray-100">
      <div
        class="relative p-6 w-full flex items-center justify-center rounded-lg border-2 border-dashed border-gray-300 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        <div class="-m-[2px] flex h-12 items-center justify-center">
          <DocumentPlusIcon class="mr-2 h-8 w-8 text-gray-400" />
          <span class="block text-sm font-semibold text-gray-900">Add a new document</span>
        </div>
        <label class="absolute inset-0 cursor-pointer">
          <input
            type="file"
            accept="image/*,.pdf"
            @change="filesSelected"
            multiple
            class="hidden"
          />
        </label>
      </div>
      <div
        v-for="doc of documents.values()"
        :key="doc.id"
        class="rounded-lg border-2 cursor-pointer"
        :class="selectedDocIds.has(doc.id) ? 'border-green-400' : 'border-transparent'"
        @click="toggleDoc(doc)"
      >
        <DocBox :doc="doc" />
      </div>
    </div>
    <div
      v-if="props.multiple"
      class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 flex justify-end gap-3"
    >
      <button
        type="button"
        @click="$emit('dismiss')"
        class="inline-flex flex-1 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Cancel
      </button>
      <button
        type="button"
        @click="done"
        class="inline-flex bg-blue-600 flex-1 justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
      >
        Done
      </button>
    </div>
  </DialogPanel>
</template>

<script setup lang="ts">
import { DialogPanel } from '@headlessui/vue'
import { ref } from 'vue'
import DocBox from '@/components/DocBox.vue'
import { DocumentPlusIcon } from '@heroicons/vue/24/outline'
import type { DocumentData } from '@/types/types'
import ModalHeader from '@/components/ModalHeader.vue'
import { computed } from 'vue'

const props = defineProps<{
  docType: string
  title: string
  documents: Map<string, DocumentData>
  preSelectedDocIds: string[]
  multiple?: boolean
}>()
const emit = defineEmits<{ (event: 'dismiss', payload?: any): void }>()
const selectedDocIds = ref<Set<string>>(new Set(props.preSelectedDocIds ?? []))

const getTitle = computed(() => {
  return props.title || `Select or add your document${props.multiple ? 's' : ''}`
})

const toggleDoc = (doc: DocumentData) => {
  if (!props.multiple) {
    const deselecting = selectedDocIds.value.has(doc.id)
    selectedDocIds.value.clear()
    !deselecting && selectedDocIds.value.add(doc.id)
    emit('dismiss', {
      selectedDocs: [...selectedDocIds.value.values()].map((docId) => props.documents.get(docId))
    })
  } else {
    selectedDocIds.value.has(doc.id)
      ? selectedDocIds.value.delete(doc.id)
      : selectedDocIds.value.add(doc.id)
  }
}

const done = () =>
  emit('dismiss', {
    selectedDocs: [...selectedDocIds.value.values()].map((docId) => props.documents.get(docId))
  })

const filesSelected = ($event: any) => {
  if (!props.multiple) {
    selectedDocIds.value.clear()
  }
  emit('dismiss', {
    selectedDocs: [...selectedDocIds.value.values()].map((docId) => props.documents.get(docId)),
    newDocFiles: [...$event.target.files]
  })
}
</script>
