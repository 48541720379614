<template>
  <div>
    <!-- <div class="mb-4">
      <InfoNote text="Got your passport handy? Verify it now!" />
    </div> -->

    <FormKit
      v-if="userProfile?.acceptedTerms"
      type="toggle"
      name="public"
      label="Add to public profile"
      :value="false"
      help="Document number and image will not be visible"
      data-test-id="passport-form-public"
    />
    <FormKit
      type="text"
      label="Passport number"
      validation="required"
      name="documentRef"
      data-test-id="passport-form-passportNumber"
    ></FormKit>
    <div class="grid grid-cols-2 gap-x-6">
      <FormKit
        validation="required"
        type="date"
        name="issueDate"
        label="Issue date"
        placeholder="Select a date"
        data-test-id="passport-form-issueDate"
      />
      <FormKit
        validation="required"
        type="date"
        name="expiryDate"
        label="Expiry date"
        placeholder="Select a date"
        data-test-id="passport-form-expiryDate"
      />
    </div>
    <FormKit
      type="autocomplete"
      name="passportNationality"
      validation="required"
      dropdownWrapper-class="bottom-full top-auto"
      label="Passport nationality"
      :options="passportNationalities"
      data-test-id="passport-form-issueCountry"
    />
  </div>
</template>

<script setup lang="ts">
// import InfoNote from '@/components/InfoNote.vue'
import { useUserProfileQuery } from '@/queries/userProfile'
const { data: userProfile } = useUserProfileQuery()

const passportNationalities = [
  {
    value: 'AFG',
    label: 'Afghan (AFG)'
  },
  {
    value: 'ALA',
    label: 'Åland Islander (ALA)'
  },
  {
    value: 'ALB',
    label: 'Albanian (ALB)'
  },
  {
    value: 'DZA',
    label: 'Algerian (DZA)'
  },
  {
    value: 'ASM',
    label: 'American Samoan (ASM)'
  },
  {
    value: 'AND',
    label: 'Andorran (AND)'
  },
  {
    value: 'AGO',
    label: 'Angolan (AGO)'
  },
  {
    value: 'AIA',
    label: 'Anguillan (AIA)'
  },
  {
    value: 'ATA',
    label: 'Antarctic (ATA)'
  },
  {
    value: 'ATG',
    label: 'Antiguan (ATG)'
  },
  {
    value: 'ARG',
    label: 'Argentine (ARG)'
  },
  {
    value: 'ARM',
    label: 'Armenian (ARM)'
  },
  {
    value: 'ABW',
    label: 'Aruban (ABW)'
  },
  {
    value: 'AUS',
    label: 'Australian (AUS)'
  },
  {
    value: 'AUT',
    label: 'Austrian (AUT)'
  },
  {
    value: 'AZE',
    label: 'Azerbaijani (AZE)'
  },
  {
    value: 'BHS',
    label: 'Bahamian (BHS)'
  },
  {
    value: 'BHR',
    label: 'Bahraini (BHR)'
  },
  {
    value: 'BGD',
    label: 'Bangladeshi (BGD)'
  },
  {
    value: 'BRB',
    label: 'Barbadian (BRB)'
  },
  {
    value: 'BLR',
    label: 'Belarusian (BLR)'
  },
  {
    value: 'BEL',
    label: 'Belgian (BEL)'
  },
  {
    value: 'BLZ',
    label: 'Belizean (BLZ)'
  },
  {
    value: 'BEN',
    label: 'Beninese (BEN)'
  },
  {
    value: 'BMU',
    label: 'Bermudian (BMU)'
  },
  {
    value: 'BTN',
    label: 'Bhutanese (BTN)'
  },
  {
    value: 'BOL',
    label: 'Bolivian (BOL)'
  },
  {
    value: 'BES',
    label: 'Bonairean (BES)'
  },
  {
    value: 'BIH',
    label: 'Bosnian (BIH)'
  },
  {
    value: 'BWA',
    label: 'Motswana (BWA)'
  },
  {
    value: 'BVT',
    label: 'Bouvet Islander (BVT)'
  },
  {
    value: 'BRA',
    label: 'Brazilian (BRA)'
  },
  {
    value: 'GBR',
    label: 'British (GBR)'
  },
  {
    value: 'GBD',
    label: 'British Dependant Territories Citizen (Territory Not Known) (GBD)'
  },
  {
    value: 'BDT',
    label: 'British Dependent Territories Citizen (BDT)'
  },
  {
    value: 'IOT',
    label: 'British Indian Ocean Territories (GBR) (IOT)'
  },
  {
    value: 'GBN',
    label: 'British National (Overseas) (GBN)'
  },
  {
    value: 'GBO',
    label: 'British Overseas Citizen (GBO)'
  },
  {
    value: 'BOT',
    label: 'British Overseas Territories Citizen (BOT)'
  },
  {
    value: 'GBP',
    label: 'British Protected Person (GBP)'
  },
  {
    value: 'GBS',
    label: 'British Subject (GBS)'
  },
  {
    value: 'VGB',
    label: 'British Virgin Islands (VGB)'
  },
  {
    value: 'BRN',
    label: 'Bruneian (BRN)'
  },
  {
    value: 'BGR',
    label: 'Bulgarian (BGR)'
  },
  {
    value: 'BFA',
    label: 'Burkinabe (BFA)'
  },
  {
    value: 'MMR',
    label: 'Burmese (MMR)'
  },
  {
    value: 'BDI',
    label: 'Burundian (BDI)'
  },
  {
    value: 'KHM',
    label: 'Cambodian (KHM)'
  },
  {
    value: 'CMR',
    label: 'Cameroonian (CMR)'
  },
  {
    value: 'CAN',
    label: 'Canadian (CAN)'
  },
  {
    value: 'CPV',
    label: 'Cape Verdean (CPV)'
  },
  {
    value: 'CYM',
    label: 'Caymanian (CYM)'
  },
  {
    value: 'CAF',
    label: 'Central African (CAF)'
  },
  {
    value: 'TCD',
    label: 'Chadian (TCD)'
  },
  {
    value: 'CHL',
    label: 'Chilean (CHL)'
  },
  {
    value: 'CHN',
    label: 'Chinese (CHN)'
  },
  {
    value: 'CXR',
    label: 'Christmas Islander (CXR)'
  },
  {
    value: 'CCK',
    label: 'Cocos Islander (CCK)'
  },
  {
    value: 'COL',
    label: 'Colombian (COL)'
  },
  {
    value: 'COM',
    label: 'Comorian (COM)'
  },
  {
    value: 'COG',
    label: 'Congolese (COG)'
  },
  {
    value: 'COK',
    label: 'Cook Islander (COK)'
  },
  {
    value: 'CRI',
    label: 'Costa Rican (CRI)'
  },
  {
    value: 'CIV',
    label: 'Ivorian (CIV)'
  },
  {
    value: 'HRV',
    label: 'Croatian (HRV)'
  },
  {
    value: 'CUB',
    label: 'Cuban (CUB)'
  },
  {
    value: 'CUW',
    label: 'Curaçaoan (CUW)'
  },
  {
    value: 'CYP',
    label: 'Cypriot (CYP)'
  },
  {
    value: 'CZE',
    label: 'Czech (CZE)'
  },
  {
    value: 'XCS',
    label: 'Czechoslovakian (XCS)'
  },
  {
    value: 'DHY',
    label: 'Dahomey (DHY)'
  },
  {
    value: 'PRK',
    label: 'North Korean (PRK)'
  },
  {
    value: 'COD',
    label: 'Congolese (COD)'
  },
  {
    value: 'DNK',
    label: 'Danish (DNK)'
  },
  {
    value: 'DJI',
    label: 'Djiboutian (DJI)'
  },
  {
    value: 'DMA',
    label: 'Dominican (DMA)'
  },
  {
    value: 'DOM',
    label: 'Dominican (DOM)'
  },
  {
    value: 'ECU',
    label: 'Ecuadorian (ECU)'
  },
  {
    value: 'EGY',
    label: 'Egyptian (EGY)'
  },
  {
    value: 'SLV',
    label: 'Salvadoran (SLV)'
  },
  {
    value: 'GNQ',
    label: 'Equatorial Guinean (GNQ)'
  },
  {
    value: 'ERI',
    label: 'Eritrean (ERI)'
  },
  {
    value: 'EST',
    label: 'Estonian (EST)'
  },
  {
    value: 'ETH',
    label: 'Ethiopian (ETH)'
  },
  {
    value: 'FLK',
    label: 'Falkland Islander (FLK)'
  },
  {
    value: 'FRO',
    label: 'Faroese (FRO)'
  },
  {
    value: 'FRY',
    label: 'Yugoslavia (FRY)'
  },
  {
    value: 'FJI',
    label: 'Fijian (FJI)'
  },
  {
    value: 'FIN',
    label: 'Finnish (FIN)'
  },
  {
    value: 'FRA',
    label: 'French (FRA)'
  },
  {
    value: 'AFI',
    label: 'French (AFI)'
  },
  {
    value: 'GUF',
    label: 'Guianan (GUF)'
  },
  {
    value: 'FXX',
    label: 'French Metropolitan (FXX)'
  },
  {
    value: 'PYF',
    label: 'French Polynesian (PYF)'
  },
  {
    value: 'ATF',
    label: 'French Southern Territories (ATF)'
  },
  {
    value: 'GAB',
    label: 'Gabonese (GAB)'
  },
  {
    value: 'GMB',
    label: 'Gambian (GMB)'
  },
  {
    value: 'GEO',
    label: 'Georgian (GEO)'
  },
  {
    value: 'DEU',
    label: 'German (DEU)'
  },
  {
    value: 'GHA',
    label: 'Ghanaian (GHA)'
  },
  {
    value: 'GIB',
    label: 'Gibraltarian (GIB)'
  },
  {
    value: 'XGL',
    label: 'Gilbert Islands (XGL)'
  },
  {
    value: 'GRC',
    label: 'Greek (GRC)'
  },
  {
    value: 'GRL',
    label: 'Greenlander (GRL)'
  },
  {
    value: 'GRD',
    label: 'Grenadian (GRD)'
  },
  {
    value: 'GLP',
    label: 'Guadeloupean (GLP)'
  },
  {
    value: 'GUM',
    label: 'Guamanian (GUM)'
  },
  {
    value: 'GGY',
    label: 'Channel Islander (GGY)'
  },
  {
    value: 'GIN',
    label: 'Guinean (GIN)'
  },
  {
    value: 'GNB',
    label: 'Bissau-Guinean (GNB)'
  },
  {
    value: 'GUY',
    label: 'Guyanese (GUY)'
  },
  {
    value: 'HTI',
    label: 'Haitian (HTI)'
  },
  {
    value: 'HMD',
    label: 'Heard & McDonald Islands (HMD)'
  },
  {
    value: 'VAT',
    label: 'Vatican Citizen (VAT)'
  },
  {
    value: 'HND',
    label: 'Honduran (HND)'
  },
  {
    value: 'HKG',
    label: 'Hong Konger (HKG)'
  },
  {
    value: 'HUN',
    label: 'Hungarian (HUN)'
  },
  {
    value: 'ISL',
    label: 'Icelandic (ISL)'
  },
  {
    value: 'IND',
    label: 'Indian (IND)'
  },
  {
    value: 'IDN',
    label: 'Indonesian (IDN)'
  },
  {
    value: 'IRN',
    label: 'Iranian (IRN)'
  },
  {
    value: 'IRQ',
    label: 'Iraqi (IRQ)'
  },
  {
    value: 'IRL',
    label: 'Irish (IRL)'
  },
  {
    value: 'IMN',
    label: 'Manx (IMN)'
  },
  {
    value: 'ISR',
    label: 'Israeli (ISR)'
  },
  {
    value: 'ITA',
    label: 'Italian (ITA)'
  },
  {
    value: 'JAM',
    label: 'Jamaican (JAM)'
  },
  {
    value: 'JPN',
    label: 'Japanese (JPN)'
  },
  {
    value: 'JEY',
    label: 'Channel Islander (JEY)'
  },
  {
    value: 'JOR',
    label: 'Jordanian (JOR)'
  },
  {
    value: 'KAZ',
    label: 'Kazakhstani (KAZ)'
  },
  {
    value: 'KEN',
    label: 'Kenyan (KEN)'
  },
  {
    value: 'KIR',
    label: 'I-Kiribati (KIR)'
  },
  {
    value: 'XXK',
    label: 'Kosovo (XXK)'
  },
  {
    value: 'UNK',
    label: 'Kosovo Resident - UN Issued Travel Document (UNK)'
  },
  {
    value: 'KWT',
    label: 'Kuwaiti (KWT)'
  },
  {
    value: 'XKW',
    label: 'Kuwait Bidoun (XKW)'
  },
  {
    value: 'KGZ',
    label: 'Kyrgyzstani (KGZ)'
  },
  {
    value: 'LAO',
    label: 'Laotian (LAO)'
  },
  {
    value: 'LVA',
    label: 'Latvian (LVA)'
  },
  {
    value: 'LBN',
    label: 'Lebanese (LBN)'
  },
  {
    value: 'LSO',
    label: 'Basotho (LSO)'
  },
  {
    value: 'LBR',
    label: 'Liberian (LBR)'
  },
  {
    value: 'LBY',
    label: 'Libyan (LBY)'
  },
  {
    value: 'LIE',
    label: 'Liechtensteiner (LIE)'
  },
  {
    value: 'LTU',
    label: 'Lithuanian (LTU)'
  },
  {
    value: 'LUX',
    label: 'Luxembourgish (LUX)'
  },
  {
    value: 'MAC',
    label: 'Macanese (MAC)'
  },
  {
    value: 'MKD',
    label: 'Macedonian (MKD)'
  },
  {
    value: 'MDG',
    label: 'Malagasy (MDG)'
  },
  {
    value: 'XML',
    label: 'Malagasy Republic (XML)'
  },
  {
    value: 'MWI',
    label: 'Malawian (MWI)'
  },
  {
    value: 'MYS',
    label: 'Malaysian (MYS)'
  },
  {
    value: 'MDV',
    label: 'Maldivian (MDV)'
  },
  {
    value: 'MLI',
    label: 'Malian (MLI)'
  },
  {
    value: 'MLT',
    label: 'Maltese (MLT)'
  },
  {
    value: 'MHL',
    label: 'Marshallese (MHL)'
  },
  {
    value: 'MTQ',
    label: 'Martinican (MTQ)'
  },
  {
    value: 'MRT',
    label: 'Mauritanian (MRT)'
  },
  {
    value: 'MUS',
    label: 'Mauritian (MUS)'
  },
  {
    value: 'MYT',
    label: 'Mahoran (MYT)'
  },
  {
    value: 'MEX',
    label: 'Mexican (MEX)'
  },
  {
    value: 'FSM',
    label: 'Micronesian (FSM)'
  },
  {
    value: 'MID',
    label: 'Midway Islands (MID)'
  },
  {
    value: 'MDA',
    label: 'Moldovan (MDA)'
  },
  {
    value: 'MCO',
    label: 'Monegasque (MCO)'
  },
  {
    value: 'MNG',
    label: 'Mongolian (MNG)'
  },
  {
    value: 'MSR',
    label: 'Montserratian (MSR)'
  },
  {
    value: 'MAR',
    label: 'Moroccan (MAR)'
  },
  {
    value: 'MOZ',
    label: 'Mozambican (MOZ)'
  },
  {
    value: 'NAM',
    label: 'Namibian (NAM)'
  },
  {
    value: 'ZZZ',
    label: 'Nationality Currently Unknown (ZZZ)'
  },
  {
    value: 'NRU',
    label: 'Nauruan (NRU)'
  },
  {
    value: 'NPL',
    label: 'Nepali (NPL)'
  },
  {
    value: 'NLD',
    label: 'Dutch (NLD)'
  },
  {
    value: 'ANT',
    label: 'Netherlands Antilles (ANT)'
  },
  {
    value: 'XEI',
    label: 'Netherlands East Indies (XEI)'
  },
  {
    value: 'NTZ',
    label: 'Neutral Zone (NTZ)'
  },
  {
    value: 'NCL',
    label: 'New Caledonian (NCL)'
  },
  {
    value: 'NZL',
    label: 'New Zealander (NZL)'
  },
  {
    value: 'NIC',
    label: 'Nicaraguan (NIC)'
  },
  {
    value: 'NER',
    label: 'Nigerien (NER)'
  },
  {
    value: 'NGA',
    label: 'Nigerian (NGA)'
  },
  {
    value: 'NIU',
    label: 'Niuean (NIU)'
  },
  {
    value: 'NFK',
    label: 'Norfolk Islander (NFK)'
  },
  {
    value: 'MNP',
    label: 'Northern Mariana Islander (MNP)'
  },
  {
    value: 'NOR',
    label: 'Norwegian (NOR)'
  },
  {
    value: 'XYZ',
    label: 'Officially Stateless (XYZ)'
  },
  {
    value: 'OMN',
    label: 'Omani (OMN)'
  },
  {
    value: 'OTC',
    label: 'Other Commonwealth (OTC)'
  },
  {
    value: 'PAK',
    label: 'Pakistani (PAK)'
  },
  {
    value: 'PLW',
    label: 'Palauan (PLW)'
  },
  {
    value: 'PSE',
    label: 'Palestinian (PSE)'
  },
  {
    value: 'PAN',
    label: 'Panamanian (PAN)'
  },
  {
    value: 'PNG',
    label: 'Papua New Guinean (PNG)'
  },
  {
    value: 'PRY',
    label: 'Paraguayan (PRY)'
  },
  {
    value: 'BFS',
    label: 'Peoples Democratic Republic of Burkino (BFS)'
  },
  {
    value: 'PER',
    label: 'Peruvian (PER)'
  },
  {
    value: 'PHL',
    label: 'Filipino (PHL)'
  },
  {
    value: 'PCN',
    label: 'Pitcairn Islander (PCN)'
  },
  {
    value: 'POL',
    label: 'Polish (POL)'
  },
  {
    value: 'PRT',
    label: 'Portuguese (PRT)'
  },
  {
    value: 'PRI',
    label: 'Puerto Rican (PRI)'
  },
  {
    value: 'QAT',
    label: 'Qatari (QAT)'
  },
  {
    value: 'XXB',
    label: 'Refugee - Article 1 of the 1951 Convention (XXB)'
  },
  {
    value: 'XXC',
    label: 'Refugee - Other (XXC)'
  },
  {
    value: 'MNE',
    label: 'Montenegrin (MNE)'
  },
  {
    value: 'SRB',
    label: 'Serbian (SRB)'
  },
  {
    value: 'REU',
    label: 'Réunionese (REU)'
  },
  {
    value: 'XRH',
    label: 'Rhodesia (XRH)'
  },
  {
    value: 'ROU',
    label: 'Romanian (ROU)'
  },
  {
    value: 'RUS',
    label: 'Russian (RUS)'
  },
  {
    value: 'RWA',
    label: 'Rwandan (RWA)'
  },
  {
    value: 'BLM',
    label: 'Saint Barthélemy Islander (BLM)'
  },
  {
    value: 'MAF',
    label: 'Saint Martin Islander (MAF)'
  },
  {
    value: 'WSM',
    label: 'Samoan (WSM)'
  },
  {
    value: 'SMR',
    label: 'Sammarinese (SMR)'
  },
  {
    value: 'STP',
    label: 'São Toméan (STP)'
  },
  {
    value: 'SAU',
    label: 'Saudi Arabian (SAU)'
  },
  {
    value: 'SEN',
    label: 'Senegalese (SEN)'
  },
  {
    value: 'SYC',
    label: 'Seychellois (SYC)'
  },
  {
    value: 'SLE',
    label: 'Sierra Leonean (SLE)'
  },
  {
    value: 'SKM',
    label: 'Sikkim (SKM)'
  },
  {
    value: 'SGP',
    label: 'Singaporean (SGP)'
  },
  {
    value: 'SXM',
    label: 'Sint Maartener (SXM)'
  },
  {
    value: 'SVK',
    label: 'Slovak (SVK)'
  },
  {
    value: 'SVN',
    label: 'Slovenian (SVN)'
  },
  {
    value: 'SLB',
    label: 'Solomon Islander (SLB)'
  },
  {
    value: 'SOM',
    label: 'Somali (SOM)'
  },
  {
    value: 'ZAF',
    label: 'South African (ZAF)'
  },
  {
    value: 'SGS',
    label: 'South Georgia and South Sandwich Islands (SGS)'
  },
  {
    value: 'KOR',
    label: 'South Korean (KOR)'
  },
  {
    value: 'SSD',
    label: 'South Sudanese (SSD)'
  },
  {
    value: 'SUN',
    label: 'Soviet Union (USSR) (SUN)'
  },
  {
    value: 'ESP',
    label: 'Spanish (ESP)'
  },
  {
    value: 'LKA',
    label: 'Sri Lankan (LKA)'
  },
  {
    value: 'SCN',
    label: 'Saint Kitts and Nevisian (SCN)'
  },
  {
    value: 'KNA',
    label: 'Saint Kitts and Nevisian (KNA)'
  },
  {
    value: 'SPM',
    label: 'Saint-Pierrais (SPM)'
  },
  {
    value: 'VCT',
    label: 'Saint Vincentian (VCT)'
  },
  {
    value: 'SHN',
    label: 'Saint Helenian (SHN)'
  },
  {
    value: 'LCA',
    label: 'Saint Lucian (LCA)'
  },
  {
    value: 'XXA',
    label: 'Stateless Person (Article 1 of 1954 Convention) (XXA)'
  },
  {
    value: 'SDN',
    label: 'Sudanese (SDN)'
  },
  {
    value: 'SUR',
    label: 'Surinamese (SUR)'
  },
  {
    value: 'SJM',
    label: 'Svalbardian (SJM)'
  },
  {
    value: 'SWZ',
    label: 'Swazi (SWZ)'
  },
  {
    value: 'SWE',
    label: 'Swedish (SWE)'
  },
  {
    value: 'CHE',
    label: 'Swiss (CHE)'
  },
  {
    value: 'SYR',
    label: 'Syrian (SYR)'
  },
  {
    value: 'TWN',
    label: 'Taiwanese (TWN)'
  },
  {
    value: 'TJK',
    label: 'Tajik (TJK)'
  },
  {
    value: 'THA',
    label: 'Thai (THA)'
  },
  {
    value: 'TLS',
    label: 'Timorese (TLS)'
  },
  {
    value: 'TGO',
    label: 'Togolese (TGO)'
  },
  {
    value: 'TKL',
    label: 'Tokelauan (TKL)'
  },
  {
    value: 'TON',
    label: 'Tongan (TON)'
  },
  {
    value: 'TTO',
    label: 'Trinidadian (TTO)'
  },
  {
    value: 'TUN',
    label: 'Tunisian (TUN)'
  },
  {
    value: 'TUR',
    label: 'Turkish (TUR)'
  },
  {
    value: 'XXT',
    label: 'Turkish Controlled Area of Cyprus (XXT)'
  },
  {
    value: 'TKM',
    label: 'Turkmen (TKM)'
  },
  {
    value: 'TCA',
    label: 'Turks and Caicos Islander (TCA)'
  },
  {
    value: 'TUV',
    label: 'Tuvaluan (TUV)'
  },
  {
    value: 'UGA',
    label: 'Ugandan (UGA)'
  },
  {
    value: 'UKR',
    label: 'Ukrainian (UKR)'
  },
  {
    value: 'ARE',
    label: 'Emirati (ARE)'
  },
  {
    value: 'UNO',
    label: 'United Nations (UNO)'
  },
  {
    value: 'UNA',
    label: 'United Nations Agency (UNA)'
  },
  {
    value: 'TZA',
    label: 'Tanzanian (TZA)'
  },
  {
    value: 'UMI',
    label: 'United States Minor Outlying Islands (UMI)'
  },
  {
    value: 'USA',
    label: 'American (USA)'
  },
  {
    value: 'VIR',
    label: 'Virgin Islander (VIR)'
  },
  {
    value: 'XXX',
    label: 'Unspecified Nationality (XXX)'
  },
  {
    value: 'URY',
    label: 'Uruguayan (URY)'
  },
  {
    value: 'UZB',
    label: 'Uzbek (UZB)'
  },
  {
    value: 'VUT',
    label: 'Ni-Vanuatu (VUT)'
  },
  {
    value: 'VEN',
    label: 'Venezuelan (VEN)'
  },
  {
    value: 'VNM',
    label: 'Vietnamese (VNM)'
  },
  {
    value: 'VDR',
    label: 'Viet-Nam, Democratic Republic of (VDR)'
  },
  {
    value: 'WLF',
    label: 'Wallisian (WLF)'
  },
  {
    value: 'AST',
    label: 'West Indian Associated States (AST)'
  },
  {
    value: 'ESH',
    label: 'Sahrawi (ESH)'
  },
  {
    value: 'YEM',
    label: 'Yemeni (YEM)'
  },
  {
    value: 'YUG',
    label: 'Yugoslavia (YUG)'
  },
  {
    value: 'ZMB',
    label: 'Zambian (ZMB)'
  },
  {
    value: 'ZWE',
    label: 'Zimbabwean (ZWE)'
  }
]
</script>
