<template>
  <div class="bg-gray-300 border border-gray-300 rounded-md overflow-hidden relative aspect-square">
    <div
      v-if="scanning"
      class="absolute inset-0 border-t-4 opacity-70 border-red-600 z-50 animate-scan-y pointer-events-none"
    ></div>
    <swiper-container
      ref="swiper"
      :grab-cursor="true"
      class="w-full aspect-square"
      :zoom="true"
      :centered-slides="true"
    >
      <swiper-slide v-for="(path, index) in imagePaths || imageUrls" :key="path">
        <div class="swiper-zoom-container">
          <img v-if="!!data" class="object-contain w-full" :src="data[index]" />
          <div v-else>
            <ion-spinner class="h-5 w-5" />
          </div></div
      ></swiper-slide>
    </swiper-container>
    <div
      class="pb-3 absolute bottom-0 left-1/2 -translate-x-1/2 z-10 text-center flex space-x-1"
      v-if="(imagePaths || imageUrls)!.length > 1"
    >
      <div
        @click="swiper.swiper.slideTo(index)"
        :class="activeIndex === index ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-500'"
        class="w-5 h-5 cursor-pointer border-2 border-gray-300 rounded-full flex justify-center items-center text-xs font-bold"
        v-for="(slide, index) of swiper?.swiper.slides"
        :key="index"
      >
        {{ index + 1 }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/zoom'

import { onMounted, ref } from 'vue'
import { register as registerSwiperComponents } from 'swiper/element/bundle'

import { useQuery } from '@tanstack/vue-query'
import { supabase } from '@/lib/supabaseClient'

registerSwiperComponents()
const props = defineProps<{
  imageUrls?: string[]
  imagePaths?: string[]
  docId: string
  scanning?: boolean
}>()

const swiper = ref()
const activeIndex = ref(0)

const { data } = useQuery({
  queryKey: ['imageUrls', props.docId],
  staleTime: Infinity,
  queryFn: () => {
    if (props.imageUrls) return props.imageUrls
    return Promise.all(
      props.imagePaths!.map(async (path: string) =>
        URL.createObjectURL((await supabase.storage.from('documents').download(path)).data!)
      )
    )
  }
})

onMounted(() => {
  swiper.value.swiper?.on(
    'realIndexChange',
    (event: any) => (activeIndex.value = event.activeIndex)
  )
})
</script>
