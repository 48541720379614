<template>
  <FormKit
    v-if="!issuerListLoading"
    type="autocomplete"
    name="certificateIssuerId"
    label="Certificate issuer"
    ref="issuerIdInput"
    placeholder="Start typing..."
    :disabled="disabled || undefined"
    :value="initialValue"
    :options="searchIssuerList"
    :option-loader="getInitialValue"
    :open-on-click="true"
    @input="$emit('input')"
  >
    <template #option="{ option }">
      <div class="formkit-option">
        {{ option.label }}
        <span class="italic" v-if="option.matches?.length">({{ option.matches.join(', ') }})</span>
      </div>
    </template>
  </FormKit>
  <FormKit
    v-else
    type="autocomplete"
    label="Certificate issuer"
    placeholder="Start typing..."
    :disabled="true"
    :options="[] as any"
    :ignore="true"
  />
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useIssuerListQuery } from '@/queries/issuerList'
import FlexSearch from 'flexsearch'

let searchIndex: FlexSearch.Document<any>
defineProps<{ disabled?: boolean; initialValue?: string }>()

const issuerIdInput = ref()

const { data: issuerList, isLoading: issuerListLoading } = useIssuerListQuery()

const getInitialValue = async (initialValue: string | null, cachedOption: any) => {
  if (cachedOption) return cachedOption
  const label = !!initialValue
    ? issuerList.value!.get(initialValue)?.name ?? 'Error, type not found'
    : undefined
  return !!label
    ? {
        value: initialValue,
        label
      }
    : false
}

const searchIssuerList = async ({ search }: any) => {
  if (!!search) {
    if (!searchIndex) {
      searchIndex = new FlexSearch.Document({
        document: { id: 'id', index: [{ field: 'name', tokenize: 'reverse' }] }
      })
      issuerList.value!.forEach((issuer: any) => searchIndex.add(issuer))
    }

    const allResults =
      searchIndex.search(search)[0]?.result.map((id) => ({
        value: id,
        label: issuerList.value?.get(id as string)?.name
      })) ?? []

    if (allResults.length) {
      return allResults
    }
    return [{ label: 'Not found (leave empty)', value: null }]
  }
  return !!issuerList.value
    ? [...issuerList.value.values()].map(({ name, id }: any) => ({
        label: name,
        value: id
      }))
    : []
}
</script>
