<template>
  <FormKit
    type="autocomplete"
    :selection-removable="'yes' as any"
    :options="options"
    v-bind="props"
  />
</template>
<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import countries from '@/data/countries.json'

const options = countries.map((country) => ({ label: country.name, value: country.code }))
const props = defineProps<{ [key: string]: any }>()
</script>
