import type { CertificateListItem } from '@/types/types'
import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'

export function useCertificateListQuery() {
  return useQuery<Map<string, CertificateListItem>>({
    queryKey: ['certificateList'],
    staleTime: Infinity,
    queryFn: () =>
      axios.get('/certificateList/profile', { baseURL: import.meta.env.VITE_API_BASE_URL }),
    select: ({ data }: any) =>
      new Map(data.items.map((item: CertificateListItem) => [item.id, item]))
  })
}
