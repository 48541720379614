import type { Database } from '@/types/database'
import { createClient, SupabaseClient } from '@supabase/supabase-js'

export const supabase: SupabaseClient<Database, 'public'> = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
  // {
  //   auth: { persistSession: false }
  // }
)
