import { supabase } from '@/lib/supabaseClient'
import { useQuery } from '@tanstack/vue-query'

export function useUserProfileQuery() {
  return useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const { data, error } = await supabase.from('Users').select().maybeSingle()
      if (error) throw new Error('Could not get user data')
      return data ?? ({} as any)
    }
  })
}
